import React, {useState, useEffect} from "react";
import {Link, Trans, useTranslation, I18nextContext, useI18next} from 'gatsby-plugin-react-i18next';
import Layout from "../components/layout";
import Seo from "../components/seo";
import {graphql} from 'gatsby';
import SlickSlider from '../components/carousel/SlickSlider';
import {StaticImage} from "gatsby-plugin-image";
import BookConsultation from "../components/pages/BookConsultation";
import CustomLocalizedLink from "../components/locales/custom-localized-link";
import { useParallax } from 'react-scroll-parallax';
import Banner from "../components/sections/banner";

import lockAspire from '../images/home/locks/aspire.png';
import lockCleo from '../images/home/locks/cleo.png';
import lockCurve from '../images/home/locks/curve.png';
import lockOrbit from '../images/home/locks/orbit.png';
import lockPivot from '../images/home/locks/pivot.png';
import lockVersa from '../images/home/locks/versa.png';
import iconTech from '../images/locks/icon-tech.svg';
import iconInstall from '../images/locks/icon-install.svg';
import iconEasy from '../images/locks/icon-easy.svg';
import phonesDesktop from '../images/home/phones-desktop.png';

import heroCurveRfid from '../images/home/hero-curve-rfid.png';
import heroCurveKeypad from '../images/home/hero-curve-keypad.png';
import heroSecuritySimplified from '../images/shared/security-simplified.svg';

import imageKeyless from '../images/home/keyless.jpg';
import imagePivot from '../images/home/pivot.jpg';

const IndexPage = () => {
  const {changeLanguage} = useI18next();
  const context = React.useContext(I18nextContext);
  const {t} = useTranslation();
  const parallax = useParallax({speed: -30});
  const parallaxRfid = useParallax(
      {
        speed: -20
      }
  );
  const parallaxKey = useParallax(
      {
        speed: -25
      }
  );

  const parallaxTextPanel1 = useParallax(
      {
        speed: 10
      }
  );

  const parallaxTextPanel1Mobile = useParallax(
      {
        speed: 10
      }
  );

  const parallaxTextPanel2 = useParallax(
      {
        speed: 10
      }
  );

  const parallaxTextPanel2Mobile = useParallax(
      {
        speed: 10
      }
  );

  return (
      <Layout>
        <Seo
            title={t('home')}
            description={t('des_home')}
        />
        <div className="home">
          <section className="hero">
            <div class="container">
              <div ref={parallax.ref} class="hero-copy">
                <img className={'ss-svg'} src={heroSecuritySimplified} alt="Security Simplified" width={'310'} height={'31'}/>
                <h1>The smarter <br />security <br />solution.</h1>
                <a role="button" hrefLang="en" href="/products/" className="btn btn--orange"><Trans>video_overlay_cta</Trans></a>
              </div>
              <img ref={parallaxRfid.ref} className={'curve-rfid'} src={heroCurveRfid} alt="Curve RFID" width={'658'} height={'801'}/>
              <img ref={parallaxKey.ref} className={'curve-key'} src={heroCurveKeypad} alt="Curve RFID" width={'634'} height={'557'}/>
            </div>
          </section>
          <section class="explore-locks">
            <div class="container">
              <div class="locks-container">
                <h2 dangerouslySetInnerHTML={{__html: t('explore_headline')}}/>
                <p><Trans>explore_copy</Trans></p>
                <div class="locks">
                  <div className={'btn-industry'}>
                    <Link to={'/products/smart-locks/curve/'}>
                      <img src={lockCurve} alt={'Curve'} width={'177'} height={'100'}/>
                      <p>Curve<span>&trade;</span></p>
                    </Link>
                  </div>
                  <div className={'btn-industry'}>
                    <Link to={'/products/smart-locks/aspire/'}>
                      <img src={lockAspire} alt={'Aspire'} width={'197'} height={'103'}/>
                      <p>Aspire<span>&trade;</span></p>
                    </Link>
                  </div>
                  <div className={'btn-industry'}>
                    <Link to={'/products/smart-locks/versa/'}>
                      <img src={lockVersa} alt={'Versa'} width={'187'} height={'113'}/>
                      <p>Versa<span>&trade;</span></p>
                    </Link>
                  </div>
                  <div className={'btn-industry'}>
                    <Link to={'/products/smart-locks/orbit/'}>
                      <img src={lockOrbit} alt={'Orbit'} width={'120'} height={'90'}/>
                      <p>Orbit<span>&trade;</span></p>
                    </Link>
                  </div>
                  <div className={'btn-industry'}>
                    <Link to={'/products/smart-locks/pivot/'}>
                      <img src={lockPivot} alt={'Pivot'} width={'195'} height={'145'}/>
                      <p>Pivot<span>&trade;</span></p>
                    </Link>
                  </div>
                  <div className={'btn-industry'}>
                    <Link to={'/products/mechanical-locks/cleo/'}>
                      <img src={lockCleo} alt={'Cleo'} width={'194'} height={'113'}/>
                      <p>Cleo<span>&trade;</span></p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Banner buttonType={'orange'}/>
          <div className="section-delimeter container" style={{marginTop: '64px'}}/>
          <section className={'lock-solutions'}>
            <div className="container">
              <h2>
                <Trans>section_one_headline</Trans>
              </h2>
              <div className={'grid'}>
                <div>
                  <img src={iconTech} alt="Technologically advanced" width={'55'} height={'59'}/>
                  <p dangerouslySetInnerHTML={{__html:t('tech_advanced')}} />
                </div>
                <div>
                  <img src={iconInstall} alt="Simple to install" width={'157'} height={'32'}/>
                  <p dangerouslySetInnerHTML={{__html:t('simple_install')}} />
                </div>
                <div>
                  <img src={iconEasy} alt="Easy to use" width={'149'} height={'47'}/>
                  <p dangerouslySetInnerHTML={{__html:t('easy_use')}} />
                </div>
              </div>
            </div>
          </section>
          <div className="section-delimeter container"/>
          <section class="innovative-lock-solutions">
            <div class="container">
              <h2 dangerouslySetInnerHTML={{__html: t('innovative_headline')}} />
              <div className="grid">
                <div className={'mobile-top'}>
                  <img src={imageKeyless} alt="keyless solution" width={'433'} height={'433'}/>
                  <div>
                    <h3 dangerouslySetInnerHTML={{__html: t('innovative_sub_one')}} />
                    <p dangerouslySetInnerHTML={{__html: t('innovative_copy_one')}} />
                    <CustomLocalizedLink
                        label={t('btn_view_electronic')}
                        goto={'/products/'}
                        cls={'btn btn--orange'}/>
                  </div>
                </div>
                <div className={'mobile-bottom'}>
                  <img src={imagePivot} alt="Pivot" width={'433'} height={'433'}/>
                  <div>
                    <h3 dangerouslySetInnerHTML={{__html: t('innovative_sub_two')}} />
                    <p dangerouslySetInnerHTML={{__html: t('innovative_copy_two')}} />
                    <CustomLocalizedLink
                        label={t('btn_more_pivot')}
                        goto={'/products/smart-locks/pivot/'}
                        cls={'btn btn--orange'}/>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="unlock-storage">
            <div className="container">
              <h2 dangerouslySetInnerHTML={{__html: t('unlock_headline')}} />
              <div className="grid">
                <div className={'mobile-top'}>
                  <h2 dangerouslySetInnerHTML={{__html: t('unlock_headline')}} />
                  <StaticImage
                      src="../images/home/smartphone-with-people.png"
                      quality={100}
                      width={450}
                      height={450}
                      formats={["auto", "webp", "avif"]}
                      loading={'lazy'}
                      alt="keyless lock"
                  />
                </div>
                <div className={'mobile-bottom'}>
                  <img src={phonesDesktop} alt="Digilock mobil aceess" width={'568'} height={'463'} className={'desktop'} />
                  <div>
                    <h3 dangerouslySetInnerHTML={{__html: t('unlock_subhead')}} />
                    <p dangerouslySetInnerHTML={{__html: t('unlock_copy')}} />
                    <CustomLocalizedLink
                        label={t('learn_more')}
                        goto={'/explore/mobile-credentials'}
                        cls={'btn btn--orange'}/>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section class="anytime-anywhere">
            <div class="container">
              <div class="grid">
                <div class="mobile-top">
                  <div>
                    <h2 dangerouslySetInnerHTML={{__html:t('anytime_headline')}} />
                    <p dangerouslySetInnerHTML={{__html:t('anytime_copy')}} />
                    <CustomLocalizedLink
                        label={t('learn_more')}
                        goto={'/products/smart-locks/digilink/'}
                        cls={'btn btn--orange'}/>
                  </div>
                </div>
                <div class="mobile-bottom">
                  <StaticImage
                      src="../images/home/digilink-tablet.png"
                      quality={100}
                      width={283}
                      height={197}
                      formats={["auto", "webp", "avif"]}
                      loading={'lazy'}
                      alt="pivot"
                  />
                  <CustomLocalizedLink
                      label={t('learn_more')}
                      goto={'/products/smart-locks/#gotoDigilink'}
                      cls={'btn btn--orange'}/>
                </div>
              </div>

            </div>
          </section>
          <div className="section-delimeter container" style={{marginTop: '36px'}}/>

          <section class="why-choose mobile">
            <h2 dangerouslySetInnerHTML={{__html: t('why_choose_headline')}} />
            <div class="why-choose-img-container" />
            <div ref={parallaxTextPanel1Mobile.ref} className="why-choose-copy-container">
              <h3 dangerouslySetInnerHTML={{__html: t('section_one_headline_2')}} />
              <p dangerouslySetInnerHTML={{__html: t('section_one_headline_2_copy')}} />
            </div>
            <div className="why-choose-img-container two" />
            <div ref={parallaxTextPanel2Mobile.ref} className="why-choose-copy-container two">
              <h3 dangerouslySetInnerHTML={{__html: t('section_one_headline_3')}} />
              <p dangerouslySetInnerHTML={{__html: t('section_one_headline_3_copy')}} />
            </div>
            <BookConsultation/>
          </section>
          <section class="why-choose desktop">
            <div class="container">
              <h2 dangerouslySetInnerHTML={{__html: t('why_choose_headline')}}/>
              <div className="why-choose-desktop-image">
                <div ref={parallaxTextPanel1.ref} className="why-choose-copy-desktop">
                  <h3 dangerouslySetInnerHTML={{__html: t('section_one_headline_2')}}/>
                  <p dangerouslySetInnerHTML={{__html: t('section_one_headline_2_copy')}} />
                </div>
              </div>
            </div>
            <div className="container">
              <div className="why-choose-desktop-image">
                <div ref={parallaxTextPanel2.ref} className="why-choose-copy-desktop">
                  <h3 dangerouslySetInnerHTML={{__html: t('section_one_headline_3')}} />
                  <p dangerouslySetInnerHTML={{__html: t('section_one_headline_3_copy')}} />
                </div>
              </div>
            </div>
            <div class="container">
              <br/><br/>
              <BookConsultation/>
            </div>
          </section>
          <div className="section-delimeter container" />
          <section className="clients">
            <div className="container">
              <h2>
                <Trans>known_trusted</Trans>
              </h2>
              <div>
                <SlickSlider/>
              </div>
            </div>
          </section>
          <div className="section-delimeter bump-up container"/>
          <section className="industries">
            <div className="container">
              <h2>
                <Trans>solutions_industry</Trans>
              </h2>
              <div className="industries-grid">
                <div className="col btn-industry workspace">
                  <Link to={'/solutions/workspace/'}>
                    <StaticImage
                        src="../images/industries/hero-workspace.png"
                        quality={100}
                        width={320}
                        height={176}
                        formats={["auto", "webp", "avif"]}
                        loading={'lazy'}
                        alt="workspace lock"
                    />
                    <p><Trans>workspace</Trans></p>
                  </Link>
                </div>
                <div className="col btn-industry education">
                  <Link to={'/solutions/education/'}>
                    <StaticImage
                        src="../images/industries/hero-education.png"
                        quality={100}
                        width={320}
                        height={176}
                        formats={["auto", "webp", "avif"]}
                        loading={'lazy'}
                        alt="education lock"
                    />
                    <p><Trans>education</Trans></p>
                  </Link>
                </div>
                <div className="col btn-industry health-fitness">
                  <Link to={'/solutions/digital-locker-locks-gyms/'}>
                    <StaticImage
                        src="../images/industries/hero-health-fitness.png"
                        quality={100}
                        width={320}
                        height={176}
                        formats={["auto", "webp", "avif"]}
                        loading={'lazy'}
                        alt="health and fitness lock"
                    />
                    <p><Trans>health_fitness</Trans></p>
                  </Link>
                </div>
                <div className="col btn-industry healthcare">
                  <Link to={'/solutions/healthcare/'}>
                    <StaticImage
                        src="../images/industries/hero-healthcare.jpg"
                        quality={100}
                        width={320}
                        height={176}
                        formats={["auto", "webp", "avif"]}
                        loading={'lazy'}
                        alt="healthcare lock"
                    />
                    <p><Trans>healthcare</Trans></p>
                  </Link>
                </div>
                <div className="col btn-industry retail">
                  <Link to={'/solutions/retail/'}>
                    <StaticImage
                        src="../images/industries/hero-retail.png"
                        quality={100}
                        width={320}
                        height={176}
                        formats={["auto", "webp", "avif"]}
                        loading={'lazy'}
                        alt="retail lock"
                    />
                    <p><Trans>retail</Trans></p>
                  </Link>
                </div>
                <div className="col btn-industry hospitality">
                  <Link to={'/solutions/hospitality/'}>
                    <StaticImage
                        src="../images/industries/hero-hospitality.png"
                        quality={100}
                        width={320}
                        height={176}
                        formats={["auto", "webp", "avif"]}
                        loading={'lazy'}
                        alt="hospitality lock"
                    />
                    <p><Trans>hospitality</Trans></p>
                  </Link>
                </div>
                <div className={"col btn-industry pro-sports " + context.language}>
                  <Link to={'/solutions/pro-sports/'}>
                    <StaticImage
                        src="../images/industries/hero-pro-sports.png"
                        quality={100}
                        width={320}
                        height={176}
                        formats={["auto", "webp", "avif"]}
                        loading={'lazy'}
                        alt="pro sports lock"
                    />
                    <p><Trans>pro_sports</Trans></p>
                  </Link>
                </div>
                <div className="col btn-industry manufacturing">
                  <Link to={'/solutions/manufacturing/'}>
                    <StaticImage
                        src="../images/industries/hero-manufacturing.png"
                        quality={100}
                        width={320}
                        height={176}
                        formats={["auto", "webp", "avif"]}
                        loading={'lazy'}
                        alt="manufacturing lock"
                    />
                    <p><Trans>manufacturing</Trans></p>
                  </Link>
                </div>
                <div className="col btn-industry government">
                  <Link to={'/solutions/government/'}>
                    <StaticImage
                        src="../images/industries/hero-government.png"
                        quality={100}
                        width={320}
                        height={176}
                        formats={["auto", "webp", "avif"]}
                        loading={'lazy'}
                        alt="government lock"
                    />
                    <p><Trans>government</Trans></p>
                  </Link>
                </div>
              </div>
              <BookConsultation/>
            </div>
          </section>

        </div>
      </Layout>
  )
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
